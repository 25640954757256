import { CheckInRepository } from "../domain/checkins/repository";
import { Functions, httpsCallable } from "firebase/functions";

export default class CheckinRepo implements CheckInRepository {
    functions: Functions
    constructor(functions: Functions) {
        this.functions = functions
    }
    async Checked(eventId: string, playerId: string, checked: boolean): Promise<void> {
        await httpsCallable(this.functions, "checkInCheckPlayer")({ eventId, playerId, checked })
    }

}