import { Database, get, ref } from "firebase/database";
import { EventPlayer, EventTable, EventTheme } from "../domain/events/model";
import EventRepository from "../domain/events/repository";
import { Functions, httpsCallable } from "firebase/functions";
import dayjs from "dayjs";

export default class EventRepo implements EventRepository {
    functions: Functions
    database: Database
    constructor(functions: Functions, database: Database) {
        this.functions = functions
        this.database = database
    }
    async GetPlayer(eventId: string, playerId: string): Promise<EventPlayer> {
        const snapshot = await get(ref(this.database, `events/${eventId}/players/${playerId}`))
        if (!snapshot.exists()) {
            throw new Error("Player not found.")
        }

        const data = snapshot.val()

        return new EventPlayer({
            id: playerId,
            group: data.group,
            firstname: data.firstname,
            lastname: data.lastname,
            caddieNo: data.caddieNo,
            hole: data.hole,
            playDate: data.playDate,
            status: data.status,
            time: data.time,
            checked: data.checked,
        })
    }
    async Get(eventId: string): Promise<EventTable> {
        const snapshot = await get(ref(this.database, `events/${eventId}`))
        if (!snapshot.exists()) {
            throw new Error("Event not found.")
        }

        const data = snapshot.val()

        const players: EventPlayer[] = []
        if (data.players) {
            for (const k in data.players) {
                players.push(new EventPlayer(data.players[k]))
            }
        }

        const theme = new EventTheme({
            logo: data?.theme?.logo || data?.logo,
            background: data?.theme?.background || data?.background,
            mainColor: data?.theme?.mainColor || data?.color,
            checkedColor: data?.theme?.checkedColor,
            checkInColor: data?.theme?.checkInColor,
            groupColor: data?.theme?.groupColor,
            holeColor: data?.theme?.holeColor,
            parColor: data?.theme?.parColor,
            pendingColor: data?.theme?.pendingColor,
            groupBackground: data?.theme?.groupBackground,
            holeBackground: data?.theme?.holeBackground,
            borderColor: data?.theme?.borderColor,
        })

        return new EventTable({
            id: eventId,
            enable: data.enable,
            name: data.name,
            startDate: dayjs(data.startDate).toDate(),
            players: players,
            type: data.type,
            theme: theme,
        })
    }
    async RequestCheckIn(eventId: string, data: EventPlayer): Promise<EventPlayer> {
        const result = await httpsCallable<any, EventPlayer>(this.functions, 'eventRequestCheckIn')({
            eventId: eventId, player: data
        })

        if (!result?.data) {
            throw new Error("Failed to request check-in")
        }

        return new EventPlayer(result.data)
    }


}