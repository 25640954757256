import { Avatar, Button, Dropdown, Flex, Layout, List, Menu, MenuProps, Modal, Typography } from 'antd'
import { CarryOutOutlined, FlagOutlined, HomeOutlined, LogoutOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons'
import { useLogout } from '../hooks/userCustomHook'
import { Content, Header } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import Sider from 'antd/es/layout/Sider'
import { useNavigate, useParams } from 'react-router-dom'
import { EventTable } from '../domain/events/model'
import { useListEvent } from '../hooks/eventCustomHook'
import LoadingScreen from './LoadingScreen'

type MenuItem = Required<MenuProps>['items'][number];

function SystemLayout({ children }: React.PropsWithChildren<{}>) {
    const { Logout } = useLogout()
    const navigate = useNavigate()
    const params = useParams()

    const { eventList, firstEvent, loading } = useListEvent(params.id)

    const [isModalOpen, setIsModalOpen] = useState(false)

    const [event, setEvent] = useState<EventTable>()

    const showModal = () => {
        setIsModalOpen(true)
    };


    const SelectEvent = (event: EventTable) => {
        window.location.replace(`/events/${event.id}`)
    }

    const items: MenuItem[] = [
        {
            key: 'logout',
            label: (
                <Button block type='text' onClick={() => Logout()}>
                    <LogoutOutlined />
                    Logout
                </Button>
            ),
        },
    ];

    useEffect(() => {
        if (!loading) {
            setEvent(firstEvent || new EventTable())
        }
    }, [firstEvent, loading])

    if (loading || !event) {
        return (<LoadingScreen />)
    }

    return (
        <Layout style={{
            minHeight: '100vh',
            backgroundImage: `url(${event?.theme?.background})`,
        }}>
            <Sider trigger={null} collapsible collapsed={true} width={'20em'}>
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    mode="inline"
                    selectable={false}
                    items={[
                        {
                            key: '/',
                            icon: <HomeOutlined />,
                            label: 'Home',
                            onClick: () => {
                                navigate('/')
                            }
                        },
                        {
                            key: 'sw',
                            icon: <SwapOutlined />,
                            label: 'Switch Event',
                            onClick: () => {
                                showModal()
                            }
                        },
                        {
                            key: 'cc',
                            icon: <FlagOutlined />,
                            label: 'Check Caddie',
                            onClick: () => { navigate(`/events/${event?.id}/caddie`) }
                        },
                        {
                            key: 'ci',
                            icon: <CarryOutOutlined />,
                            label: 'Check In',
                            onClick: () => { navigate(`/events/${event?.id}/checkins`) }
                        }
                    ]}
                />
            </Sider>
            <Layout>
                <Header style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Flex>
                        <Dropdown menu={{ items }} trigger={['click']}>
                            <Button type='text'>
                                <Typography.Text style={{ color: '#FFFFFF' }}>
                                    <Avatar style={{ backgroundColor: '#87d068', marginRight: '0.5rem' }} size={'small'} icon={<UserOutlined />} />
                                    Frontend User
                                </Typography.Text>
                            </Button>
                        </Dropdown>
                    </Flex>
                </Header>
                <Content style={{
                    padding: '0 48px',
                    height: '100%',
                }}>
                    {children}
                </Content>
            </Layout>
            <Modal
                title="Select Event"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={false}
            >
                <List>
                    {eventList.map((e: EventTable) => (
                        <List.Item key={e.id}>
                            <Button type='link' onClick={() => {
                                SelectEvent(e)
                            }}>
                                {e.name}
                            </Button>
                        </List.Item>
                    ))}
                </List>
            </Modal>
        </Layout>
    )
}

export default SystemLayout