import { UserAddOutlined } from '@ant-design/icons'
import { Button, DatePicker, Divider, Flex, Form, Input, Select, Switch, theme, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckIn } from '../hooks/eventCustomHook';
import { useRequestPlayer } from '../hooks/playerCustomHook';
import { Player } from '../domain/players/model';
import { EventPlayer } from '../domain/events/model';

function FormScreen() {
  const params = useParams()
  const navigate = useNavigate()

  if (!params?.id) {
    navigate('/')
  }

  const { RequestPlayer, loading: playerLoading } = useRequestPlayer()
  const { CheckIn, loading: checkInLoading } = useCheckIn()
  const [isCheckIn, setIsCheckIn] = React.useState(false)

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    playDate: dayjs().format('YYYY-MM-DD'),
    time: dayjs().minute(0).second(0).format('HH:mm'),
    hole: 1,
    group: 1,
    caddieNo: ''
  })

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleSubmit = () => {
    const player = new Player()
    player.name = form.firstname
    player.surname = form.lastname

    RequestPlayer(player).then((result) => {
      if (isCheckIn) {
        const checkIn = new EventPlayer()
        checkIn.id = result?.id || ''
        checkIn.group = form.group
        checkIn.hole = form.hole
        checkIn.caddieNo = form.caddieNo
        checkIn.playDate = form.playDate
        checkIn.time = form.time
        checkIn.firstname = result?.name || form.firstname
        checkIn.lastname = result?.surname || form.lastname

        CheckIn(params?.id || '', checkIn).then(() => {
          navigate('/')
        })
      }
    })
  }

  return (
    < >
      <Flex
        style={{
          marginTop: '1.5rem',
          background: colorBgContainer,
          minHeight: '80vh',
          padding: '2rem',
          borderRadius: borderRadiusLG,
        }}
        vertical
      >
        <Form layout='vertical' onFinish={handleSubmit}>
          <Typography.Title level={3} ><UserAddOutlined /> Add New Player</Typography.Title>
          <Divider />
          <Flex justify='space-between' gap={20} style={{ maxWidth: '100%', width: '60rem' }}>
            <Form.Item label="Name" style={{ width: '100%' }}>
              <Input type='text' value={form.firstname} onChange={(e) => setForm({ ...form, firstname: e.target.value })} />
            </Form.Item>

            <Form.Item label="Surname" style={{ width: '100%' }}>
              <Input type='text' value={form.lastname} onChange={(e) => setForm({ ...form, lastname: e.target.value })} />
            </Form.Item>
          </Flex>
          <Flex>
            <Form.Item label="Check In" layout='horizontal'>
              <Switch checked={isCheckIn} onChange={(checked) => setIsCheckIn(checked)} />
            </Form.Item>
          </Flex>

          {
            isCheckIn && (
              <>
                <Flex justify='space-between' gap={20} style={{ maxWidth: '100%', width: '60rem' }}>
                  <Form.Item label="Play Date" style={{ width: '100%' }}>
                    <DatePicker
                      size='large'
                      inputReadOnly={true}
                      allowClear={false}
                      disabled
                      needConfirm={false}
                      style={{ width: '100%' }}
                      value={dayjs(form.playDate)}
                      onChange={(date) => {
                        setForm({ ...form, playDate: date.format('YYYY-MM-DD') })
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Time" style={{ width: '100%' }}>
                    <TimePicker
                      disabled
                      inputReadOnly={true}
                      allowClear={false}
                      needConfirm={false}
                      style={{ width: '100%' }}
                      format={'HH:mm'}
                      minuteStep={10}
                      size='large'
                      value={dayjs(form.time, 'HH:mm')}
                      onChange={(time) => {
                        setForm({ ...form, time: time.format('HH:mm') })
                      }}
                    />
                  </Form.Item>
                </Flex>
                <Flex justify='space-between' gap={20} style={{ maxWidth: '100%', width: '60rem' }}>
                  <Form.Item label="Group" style={{ width: '100%' }}>
                    <Select
                      value={form.group}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setForm({ ...form, group: value })
                      }}
                    >
                      <Select.Option value={1}>Group 1</Select.Option>
                      <Select.Option value={2}>Group 2</Select.Option>
                      <Select.Option value={3}>Group 3</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Hole" style={{ width: '100%' }}>
                    <Select
                      value={form.hole}
                      size='large'
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        setForm({ ...form, hole: value })
                      }}
                    >
                      {new Array(18).fill(0).map((_, index) => (
                        <Select.Option key={index} value={index + 1}>Hole {index + 1}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Flex>
                <Flex justify='space-between' gap={20} style={{ maxWidth: '100%', width: '60rem' }}>
                  <Form.Item label="Caddie No" style={{ width: '100%' }}>
                    <Input />
                  </Form.Item>
                </Flex>
              </>
            )
          }
          <Flex gap={20}>
            <Button type='primary' loading={playerLoading || checkInLoading} htmlType='submit'>Save</Button>
            <Button type='default' onClick={() => navigate('/')}>Back</Button>
          </Flex>
        </Form>
      </Flex>
    </>
  )
}

export default FormScreen