import React, { useState } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Checkbox, Flex, Form, Input } from 'antd'
import Link from 'antd/es/typography/Link'
import { useLogin } from '../hooks/userCustomHook'

function LoginScreen() {
  const { Login, loading } = useLogin()
  const [form, setForm] = useState({
    username: '',
    password: '',
  })

  return (
    <Flex gap="middle" align="start" vertical>
      <Flex style={{ width: '100%', minHeight: '70vh' }} justify={'center'} align={'center'}>
        <Form layout='vertical' style={{ minWidth: '20vw' }} onFinish={() => {
          Login(form.username, form.password)
        }}>
          <Form.Item>
            <Flex style={{ width: '100%' }} justify={'center'}>
              <Avatar style={{ border: '4px solid white' }} size={96} icon={<UserOutlined />} />
            </Flex>
          </Form.Item>
          <Form.Item>
            <Input
              type='text'
              size='large'
              placeholder='Username'
              prefix={<UserOutlined />}
              readOnly={loading}
              onChange={(e) => setForm({ ...form, username: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Input
              size='large'
              type='password'
              placeholder='Password'
              prefix={<LockOutlined />}
              readOnly={loading}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </Form.Item>
          <Form.Item>
            <Flex style={{ width: '100%' }} justify={'space-between'}>
              <Checkbox checked>Remember me</Checkbox>
              <Link href={'#'}>
                Forgot Password?
              </Link>
            </Flex>
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' size='large' block loading={loading}>Sign In</Button>
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  )
}

export default LoginScreen