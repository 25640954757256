import { useCallback, useState } from "react"
import PlayerService from "../domain/players/service"
import { functions } from "../firebase"
import PlayerRepo from "../repository/players"
import { Player } from "../domain/players/model"
import { message } from "antd"

const playerService = new PlayerService(new PlayerRepo(functions))

export const useRequestPlayer = (svc = playerService) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const RequestPlayer = useCallback(async (data: Player) => {
        setLoading(true)
        return svc.RequestNewPlayer(data).then((result) => {
            message.success("Request player success")
            return result
        }).catch((err) => {
            message.error(err.message)
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return {
        loading, error, RequestPlayer
    }
}