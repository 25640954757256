import { useCallback, useState } from "react"
import { functions } from "../firebase"
import CheckinRepo from "../repository/checkins"
import { CheckInService } from "../domain/checkins/service"
import { message } from "antd"

const checkInService = new CheckInService(new CheckinRepo(functions))

export const useChecked = (svc = checkInService) => {
    const [loading, setLoading] = useState(false)

    const Checked = useCallback(async (eventId: string, playerId: string, checked: boolean) => {
        setLoading(true)
        return svc.Checked(eventId, playerId, checked).then(() => {
            message.success("CheckIn saved")
        }).catch((err) => {
            message.error(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return {
        loading, Checked
    }
}