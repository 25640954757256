import React, { useEffect, useState } from 'react'
import { CheckCircleFilled, ClockCircleOutlined, FlagOutlined, SearchOutlined, UserAddOutlined } from '@ant-design/icons'

import { Button,  Divider, Flex, Form, Input, List, message, Modal, Select, theme, Typography } from 'antd'
import { useCheckIn, useGetCheckIn, useListEvent } from '../hooks/eventCustomHook';
import LoadingScreen from './LoadingScreen';
import { EventPlayer, EventTable } from '../domain/events/model';
import { useNavigate, useParams } from 'react-router-dom';
function MainScreen() {
    const params = useParams()

    const navigate = useNavigate()
    const { firstEvent, loading } = useListEvent(params.id)
    const { CheckIn, loading: loadingCheckIn } = useCheckIn()
    const { Get: GetCheckIn, loading: loadingGetCheckIn } = useGetCheckIn()
    const [event, setEvent] = useState<EventTable>()
    const [players, setPlayers] = useState<EventPlayer[]>([])
    const [form, setForm] = useState({
        search: ''
    })
    const [checkInForm, setCheckInForm] = useState<EventPlayer>()
    const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false)

    const [isSearchNotFound, setIsSearchNotFound] = useState(false)

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();



    const handleSearch = () => {
        if (form.search.length < 3) {
            return
        }

        setIsSearchNotFound(false)

        const playerList = event?.players.filter((player) => `${player.firstname} ${player.lastname}`.toLocaleLowerCase().includes(form.search.toLocaleLowerCase()))
        if (playerList?.length) {
            setPlayers(playerList)
        } else {
            setIsSearchNotFound(true)
            setPlayers([])
        }
    }

    const handleShowModalCheckIn = (player: EventPlayer) => {
        setIsCheckInModalOpen(true)

        GetCheckIn(event?.id || '', player.id).then((result) => {
            setCheckInForm(result)
        }).catch(err => {
            setIsCheckInModalOpen(false)
            message.error(err)
        })
    }

    const handleCheckIn = () => {
        if (!event || !checkInForm) {
            return
        }

        CheckIn(event.id, checkInForm).then(() => {
            setIsCheckInModalOpen(false)
            window.location.reload()
        })
    }

    const handleCancelCheckIn = () => {
        setIsCheckInModalOpen(false)
    }

    useEffect(() => {
        if (!loading) {
            setEvent(firstEvent)
        }
    }, [loading, firstEvent])

    if (loading) {
        return (<LoadingScreen />)
    }

    return (

        <>
            <Flex
                style={{
                    marginTop: '1.5rem',
                    background: colorBgContainer,
                    minHeight: '80vh',
                    padding: '2rem',
                    borderRadius: borderRadiusLG,
                }}
                vertical
            >
                <Flex justify='center'>
                    <Flex>
                        <img src={event?.getLogoUrl()} alt="avatar" style={{ maxWidth: '100%', minWidth: '6rem', minHeight: '3rem', maxHeight: '6rem', overflow: 'hidden' }} />
                    </Flex>
                </Flex>
                <Divider />
                <Flex vertical
                    style={{
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        minHeight: '50vh',
                    }}
                >
                    <Flex
                        justify='space-between'
                        gap={20}
                    >
                        <div></div>
                        <Form
                            layout='vertical'
                            onFinish={handleSearch}
                            style={{
                                background: 'rgba(255,255,255,0.9)',
                                maxWidth: '50rem',
                                padding: '1rem',
                                minWidth: '60%',
                                borderRadius: borderRadiusLG
                            }}>
                            <Form.Item>
                                <Input
                                    type='text'
                                    placeholder='Search Player'
                                    size='large'
                                    value={form.search}
                                    onChange={(e) => setForm({ ...form, search: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Flex justify='center' gap={20}>
                                    <Button type='default' htmlType='submit' block size='large'><SearchOutlined /> Search Player</Button>
                                    <Button type='default' block size='large' onClick={() => { navigate(`/events/${event?.id}/form`) }}><UserAddOutlined /> Add New Player</Button>
                                </Flex>
                            </Form.Item>
                            {isSearchNotFound && (
                                <Flex style={{ backgroundColor: 'rgba(255,255,255)', width: '100%' }}>
                                    <List
                                        header={<Typography.Text strong>Search Results : </Typography.Text>}
                                        footer={<></>}
                                        style={{ width: '100%' }}
                                        bordered
                                        dataSource={['Not Found.']}
                                        renderItem={(item) => (
                                            <List.Item style={{ justifyContent: 'center' }}>
                                                <Typography.Text>
                                                    {item}
                                                </Typography.Text>
                                            </List.Item>
                                        )}
                                    />
                                </Flex>
                            )}
                            {players.length > 0 && (
                                <Flex style={{ backgroundColor: 'rgba(255,255,255)', width: '100%' }}>
                                    <List
                                        header={<Typography.Text strong>Search Results : </Typography.Text>}
                                        style={{ width: '100%' }}
                                        bordered
                                        dataSource={players}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <Button type='text' onClick={() => { handleShowModalCheckIn(item) }} block style={{ justifyContent: 'left' }}>
                                                    <Typography.Text>
                                                        {
                                                            item.isPending() ? <ClockCircleOutlined /> : <CheckCircleFilled style={{ color: 'green' }} />
                                                        }
                                                    </Typography.Text> {item.firstname} {item.lastname}
                                                </Button>
                                            </List.Item>
                                        )}
                                    />
                                </Flex>
                            )}
                        </Form>
                        <div></div>
                    </Flex>
                </Flex>
                <Flex justify='start' >
                    <Flex>
                        <Button type='default' onClick={() => { navigate(`/events/${event?.id}/caddie`) }} ><FlagOutlined />Check Caddie</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Modal
                title={(
                    <Typography.Text>
                        {checkInForm?.isCheckIn() && <CheckCircleFilled style={{ fontSize: '1rem', color: 'green', marginRight: '0.25rem' }} />}
                        Request Check In
                    </Typography.Text>
                )}
                okText='Check In'
                cancelText='Close'
                open={isCheckInModalOpen}
                onOk={handleCheckIn}
                onCancel={handleCancelCheckIn}
                loading={loadingCheckIn || loadingGetCheckIn}
            >
                <Form
                    layout='vertical'
                    onFinish={handleSearch}
                    style={{
                        background: 'rgba(255,255,255,0.9)',
                        maxWidth: '50rem',
                        padding: '1rem',
                        minWidth: '60%',
                        borderRadius: borderRadiusLG
                    }}>
                    <Form.Item label='Firstname'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.firstname}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.firstname = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Lastname'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.lastname}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.lastname = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Group'>
                        <Select
                            value={checkInForm?.group}
                            size='large'
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.group = value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        >
                            <Select.Option value={1}>Group 1</Select.Option>
                            <Select.Option value={2}>Group 2</Select.Option>
                            <Select.Option value={3}>Group 3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Hole'>
                        <Select
                            value={checkInForm?.hole}
                            size='large'
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.hole = value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        >
                            {new Array(18).fill(0).map((_, index) => (
                                <Select.Option key={index} value={index + 1}>Hole {index + 1}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Caddie No.'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.caddieNo}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.caddieNo = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default MainScreen