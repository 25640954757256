import { EventPlayer, EventTable } from "./model";
import EventRepository from "./repository";

export default class EventService {
    repo: EventRepository;
    constructor(repo: EventRepository) {
        this.repo = repo
    }

    async RequestCheckIn(eventId: string, data: EventPlayer): Promise<EventPlayer> {
        return this.repo.RequestCheckIn(eventId, data)
    }

    async Get(eventId: string): Promise<EventTable> {
        return this.repo.Get(eventId)
    }

    async GetPlayer(eventId: string, playerId: string): Promise<EventPlayer> {
        return this.repo.GetPlayer(eventId, playerId)
    }
}