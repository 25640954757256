import { useCallback, useEffect, useState } from "react"
import { db, functions } from "../firebase"
import { get, onValue, ref } from "firebase/database"
import { EventPlayer, EventTable, EventTheme } from "../domain/events/model"
import EventService from "../domain/events/service"
import EventRepo from "../repository/events"
import { message } from "antd"
import dayjs from "dayjs"

const eventService = new EventService(new EventRepo(functions, db))

export const useEventTable = (id: string) => {
    const [event, setEvent] = useState<EventTable>()
    const [loading, setLoading] = useState(true)
    const [eventId, setEventId] = useState(id)

    const Reload = useCallback((id: string) => {
        setEventId(id)
    }, [])

    useEffect(() => {
        if (eventId) {
            const eventRef = ref(db, `events/${eventId}`)
            get(eventRef).then((snapshot) => {
                const data = snapshot.val()

                const players: EventPlayer[] = []
                if (data.players) {
                    for (const k in data.players) {
                        players.push(new EventPlayer(data.players[k]))
                    }
                }

                const theme = new EventTheme({
                    logo: data?.theme?.logo || data?.logo,
                    background: data?.theme?.background || data?.background,
                    mainColor: data?.theme?.mainColor || data?.color,
                    checkedColor: data?.theme?.checkedColor,
                    checkInColor: data?.theme?.checkInColor,
                    groupColor: data?.theme?.groupColor,
                    holeColor: data?.theme?.holeColor,
                    parColor: data?.theme?.parColor,
                    pendingColor: data?.theme?.pendingColor,
                    groupBackground: data?.theme?.groupBackground,
                    holeBackground: data?.theme?.holeBackground,
                    borderColor: data?.theme?.borderColor,
                })

                const event = new EventTable({
                    id: eventId,
                    name: data.name,
                    type: data.type,
                    startDate: data.startDate,
                    enable: data.enable,
                    players: players,
                    theme: theme,
                })

                setEvent(event)

                setLoading(false)
            })
        }
    }, [eventId])

    return {
        event, loading, Get: Reload
    }
}

export const useSuscribeEvent = (id: string) => {
    const [event, setEvent] = useState<EventTable>()
    const [loading, setLoading] = useState(true)
    const [eventId, setEventId] = useState(id)

    const Reload = useCallback((id: string) => {
        setEventId(id)
    }, [])

    useEffect(() => {
        if (eventId) {
            const eventRef = ref(db, `events/${eventId}`)
            return onValue(eventRef, (snapshot) => {
                const data = snapshot.val()

                const players: EventPlayer[] = []
                if (data.players) {
                    for (const k in data.players) {
                        players.push(new EventPlayer(data.players[k]))
                    }
                }

                const theme = new EventTheme({
                    logo: data?.theme?.logo || data?.logo,
                    background: data?.theme?.background || data?.background,
                    mainColor: data?.theme?.mainColor || data?.color,
                    checkedColor: data?.theme?.checkedColor,
                    checkInColor: data?.theme?.checkInColor,
                    groupColor: data?.theme?.groupColor,
                    holeColor: data?.theme?.holeColor,
                    parColor: data?.theme?.parColor,
                    pendingColor: data?.theme?.pendingColor,
                    groupBackground: data?.theme?.groupBackground,
                    holeBackground: data?.theme?.holeBackground,
                    borderColor: data?.theme?.borderColor,
                })

                const event = new EventTable({
                    id: eventId,
                    name: data.name,
                    type: data.type,
                    startDate: dayjs(data.startDate).toDate(),
                    enable: data.enable,
                    players: players.sort((a, b) => `${a.status ? 1 : 0}-${a.hole.toString().padStart(2, '0')}-${a.group}`.localeCompare(`${b.status ? 1 : 0}-${b.hole.toString().padStart(2, '0')}-${b.group}`)),
                    theme: theme
                })

                setEvent(event)

                setLoading(false)
            })
        }
    }, [eventId])

    return {
        event, loading, Get: Reload
    }
}

export const useEvent = (eventId?: string, svc = eventService) => {
    const [event, setEvent] = useState<EventTable>()
    const [loading, setLoading] = useState(true)
    const Get = useCallback(async (id: string): Promise<void | EventTable> => {
        setLoading(true)
        return svc.Get(id).then((event: EventTable) => {
            setEvent(event)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        if (eventId) {
            const eventRef = ref(db, `events/${eventId}`)
            get(eventRef).then((snapshot) => {
                const data = snapshot.val()

                const players: EventPlayer[] = []
                if (data.players) {
                    for (const k in data.players) {
                        players.push(new EventPlayer(data.players[k]))
                    }
                }

                const theme = new EventTheme({
                    logo: data?.theme?.logo || data?.logo,
                    background: data?.theme?.background || data?.background,
                    mainColor: data?.theme?.mainColor || data?.color,
                    checkedColor: data?.theme?.checkedColor,
                    checkInColor: data?.theme?.checkInColor,
                    groupColor: data?.theme?.groupColor,
                    holeColor: data?.theme?.holeColor,
                    parColor: data?.theme?.parColor,
                    pendingColor: data?.theme?.pendingColor,
                    groupBackground: data?.theme?.groupBackground,
                    holeBackground: data?.theme?.holeBackground,
                    borderColor: data?.theme?.borderColor,
                })

                const event = new EventTable({
                    id: eventId,
                    name: data.name,
                    type: data.type,
                    startDate: dayjs(data.startDate).toDate(),
                    enable: data.enable,
                    players: players,
                    theme: theme
                })

                setEvent(event)

                setLoading(false)
            })
        } else {
            const eventRef = ref(db, `events`)
            get(eventRef).then((snapshot) => {
                const child = snapshot.val()
                let event = new EventTable()

                for (const key in child) {
                    const data = child[key]

                    const players: EventPlayer[] = []
                    if (data.players) {
                        for (const k in data.players) {
                            players.push(new EventPlayer(data.players[k]))
                        }
                    }

                    const theme = new EventTheme({
                        logo: data?.theme?.logo || data?.logo,
                        background: data?.theme?.background || data?.background,
                        mainColor: data?.theme?.mainColor || data?.color,
                        checkedColor: data?.theme?.checkedColor,
                        checkInColor: data?.theme?.checkInColor,
                        groupColor: data?.theme?.groupColor,
                        holeColor: data?.theme?.holeColor,
                        parColor: data?.theme?.parColor,
                        pendingColor: data?.theme?.pendingColor,
                        groupBackground: data?.theme?.groupBackground,
                        holeBackground: data?.theme?.holeBackground,
                        borderColor: data?.theme?.borderColor,
                    })

                    const eventData = new EventTable({
                        id: key,
                        name: data.name,
                        type: data.type,
                        startDate: dayjs(data.startDate).toDate(),
                        enable: data.enable,
                        players: players,
                        theme: theme
                    })

                    if ((eventData.id === '') || (eventData.startDate.getDate() > event.startDate.getDate())) {
                        event = eventData
                    }
                }

                setEvent(event)

            })
        }
    }, [eventId, svc])

    return {
        event, loading, Get
    }
}

export const useListEvent = (eventId?: string) => {
    const [eventList, setEventList] = useState<EventTable[]>([])
    const [loading, setLoading] = useState(true)
    const [firstEvent, setFirstEvent] = useState<EventTable>()
    const [isEventNotFound, setIsEventNotFound] = useState(false)

    useEffect(() => {
        get(ref(db, `events`)).then((snapshot) => {
            if (snapshot.size === 0) {
                setIsEventNotFound(true)
                setLoading(false)
                return
            }

            const data = snapshot.val()
            let newFirstEvent = new EventTable()

            const list: EventTable[] = []
            for (const key in data) {

                const players: EventPlayer[] = []
                if (data[key].players) {
                    for (const k in data[key].players) {
                        players.push(new EventPlayer(data[key].players[k]))
                    }
                }

                const theme = new EventTheme({
                    logo: data[key]?.theme?.logo || data[key]?.logo,
                    background: data[key]?.theme?.background || data[key]?.background,
                    mainColor: data[key]?.theme?.mainColor || data[key]?.color,
                    checkedColor: data[key]?.theme?.checkedColor,
                    checkInColor: data[key]?.theme?.checkInColor,
                    groupColor: data[key]?.theme?.groupColor,
                    holeColor: data[key]?.theme?.holeColor,
                    parColor: data[key]?.theme?.parColor,
                    pendingColor: data[key]?.theme?.pendingColor,
                    groupBackground: data[key]?.theme?.groupBackground,
                    holeBackground: data[key]?.theme?.holeBackground,
                    borderColor: data[key]?.theme?.borderColor,
                })

                const event = new EventTable({
                    id: key,
                    name: data[key].name,
                    type: data[key].type,
                    startDate: dayjs(data[key].startDate).toDate(),
                    enable: data[key].enable,
                    players: players,
                    theme: theme
                })

                list.push(event)

                if (eventId) {
                    if (eventId === key) {
                        newFirstEvent = event
                    }
                } else {
                    if (newFirstEvent.id === '' || (newFirstEvent.startDate.getTime() < event.startDate.getTime())) {
                        newFirstEvent = event
                    }
                }
            }

            setEventList(list.sort((a: EventTable, b: EventTable) => a.id < b.id ? -1 : 1))
            setFirstEvent(newFirstEvent)
            setLoading(false)
        })
    }, [eventId])

    return { eventList, loading, firstEvent, isEventNotFound }
}

export const useCheckIn = (svc = eventService) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const CheckIn = useCallback(async (eventId: string, player: EventPlayer) => {
        setLoading(true)
        return svc.RequestCheckIn(eventId, player).then(() => {
            message.success("Request checkin success")
        }).catch((err) => {
            message.error(err.message)
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return {
        loading, error, CheckIn
    }
}

export const useGetCheckIn = (svc = eventService) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [checkIn, setCheckIn] = useState<EventPlayer>()

    const Get = useCallback(async (eventId: string, playerId: string): Promise<EventPlayer> => {
        setLoading(true)
        return svc.GetPlayer(eventId, playerId).then((result) => {
            setCheckIn(result)
            return result
        }).catch((err) => {
            setError(err)
            throw err
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return { loading, error, checkIn, Get }
}