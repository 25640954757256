import { Functions, httpsCallable } from 'firebase/functions';
import { Player } from '../domain/players/model';
import PlayerRepository from '../domain/players/repository';

export default class PlayerRepo implements PlayerRepository {
    functions: Functions
    constructor(functions: Functions) {
        this.functions = functions
    }

    async RequestNewPlayer(input: Player): Promise<Player> {
        const result = await httpsCallable<Player, Player>(this.functions, 'playerRequestNew')(input)

        return new Player({
            id: result.data.id,
            name: result.data.name,
            surname: result.data.surname,
            status: result.data.status,
        })
    }

}