import React, { useEffect, useState } from 'react'
import { Button, Divider, Flex, Form, Input, List, message, Modal, Select, theme, Typography } from 'antd'
import { CarryOutOutlined, CheckCircleFilled, ClockCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom';
import { useCheckIn, useGetCheckIn, useSuscribeEvent } from '../hooks/eventCustomHook';
import LoadingScreen from './LoadingScreen';
import { EventPlayer } from '../domain/events/model';

function CheckInScreen() {
    const params = useParams()

    const navigate = useNavigate()

    if (!params?.id) {
        navigate(-1)
    }

    const { CheckIn, loading: loadingCheckIn } = useCheckIn()
    const { event, loading } = useSuscribeEvent(params?.id || '')
    const { Get: GetCheckIn, loading: loadingGetCheckIn } = useGetCheckIn()
    const [checkInForm, setCheckInForm] = useState<EventPlayer>()
    const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const [openModalLoading, setOpenModalLoading] = useState(false)

    const [players, setPlayers] = useState(event?.players || [])

    const handleSearch = (input: string) => {
        const data = event?.players.filter((player) => `${player.firstname} ${player.lastname}`.toLocaleLowerCase().includes(input.toLocaleLowerCase()))

        setPlayers(data || [])
    }

    const handleShowModalCheckIn = (player: EventPlayer) => {
        setIsCheckInModalOpen(true)
        setOpenModalLoading(true)

        setTimeout(() => {
            GetCheckIn(event?.id || '', player.id).then((result) => {
                setCheckInForm(result)
            }).catch(err => {
                setIsCheckInModalOpen(false)
                message.error(err)
            }).finally(() => {
                setOpenModalLoading(false)
            })
        }, 200)

    }

    const handleCheckIn = () => {
        if (!event || !checkInForm) {
            return
        }

        CheckIn(event.id, checkInForm).then(() => {
            setIsCheckInModalOpen(false)
            window.location.reload()
        })
    }

    const handleCancelCheckIn = () => {
        setIsCheckInModalOpen(false)
    }

    useEffect(() => {
        if (!loading) {
            setPlayers(event?.players || [])
        }
    }, [event, loading])

    if (loading) {
        return <LoadingScreen />
    }

    return (
        < >
            <Flex
                style={{
                    marginTop: '1.5rem',
                    background: colorBgContainer,
                    minHeight: '80vh',
                    padding: '2rem',
                    borderRadius: borderRadiusLG,
                }}
                vertical>
                <Form
                    layout='vertical'
                    onFinish={handleSearch}
                    style={{
                        background: 'rgba(255,255,255,0.9)',
                        padding: '1rem',
                        borderRadius: borderRadiusLG
                    }}>
                    <Typography.Title level={3} ><CarryOutOutlined /> Check In</Typography.Title>
                    <Flex style={{ width: '100%', justifyContent: 'center' }}>
                        <img src={event?.getLogoUrl()} alt="avatar" style={{ maxWidth: '100%', minWidth: '3rem', minHeight: '1rem', maxHeight: '6rem', overflow: 'hidden' }} />
                    </Flex>
                    <Divider />
                    <Form.Item>
                        <Flex justify='space-between' gap={20}>
                            <Input
                                type='text'
                                placeholder='Search Player'
                                prefix={<SearchOutlined />}
                                size='large'
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Flex>
                    </Form.Item>
                    <Flex style={{ width: '100%', overflowX: 'scroll' }}>
                        <List style={{ width: '100%', minWidth: '50rem' }}>
                            <List.Item style={{ padding: '1rem' }}>
                                <Flex style={{ width: '100%' }}>
                                    <Typography.Text style={{ width: '10%', textAlign: 'center' }} strong>Check In</Typography.Text>
                                    <Typography.Text style={{ width: '30%' }} strong>Player Name</Typography.Text>
                                    <Typography.Text style={{ width: '20%' }} strong>Hole</Typography.Text>
                                    <Typography.Text style={{ width: '20%' }} strong>Group</Typography.Text>
                                    <Typography.Text style={{ width: '20%' }} strong>Caddie No.</Typography.Text>
                                </Flex>
                            </List.Item>
                            <Flex vertical style={{ width: '100%', height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {players.length > 0 ? players.map((data: EventPlayer) => {
                                    return (
                                        <List.Item key={data.id} style={{ padding: '0' }}>
                                            <Button type='text' block size='large' style={{ height: '4rem' }} onClick={() => { handleShowModalCheckIn(data) }}>
                                                <Flex style={{ width: '100%', textAlign: 'left' }}>
                                                    <Typography.Text style={{ width: '10%', textAlign: 'center' }}>
                                                        {data.isCheckIn() ? <CheckCircleFilled style={{ fontSize: '1.5rem', color: 'green' }} /> : <ClockCircleOutlined style={{ fontSize: '1.5rem', color: 'gray' }} />}
                                                    </Typography.Text>
                                                    <Typography.Text style={{ width: '30%' }}>{data.firstname} {data.lastname}</Typography.Text>
                                                    <Typography.Text style={{ width: '20%' }}>Hole {data.hole}</Typography.Text>
                                                    <Typography.Text style={{ width: '20%' }}>Group {data.group}</Typography.Text>
                                                    <Typography.Text style={{ width: '20%' }}>{data.caddieNo}</Typography.Text>
                                                </Flex>
                                            </Button>
                                        </List.Item>
                                    )
                                }) : (
                                    <List.Item>
                                        <Typography.Text style={{ width: '100%', textAlign: 'center' }}>Not Found.</Typography.Text>
                                    </List.Item>
                                )}
                            </Flex>
                        </List>
                    </Flex>
                </Form>
            </Flex>
            <Modal
                title={(
                    <Typography.Text>
                        {checkInForm?.isCheckIn() && <CheckCircleFilled style={{ fontSize: '1rem', color: 'green', marginRight: '0.25rem' }} />}
                        Request Check In
                    </Typography.Text>
                )}
                okText='Check In'
                cancelText='Close'
                open={isCheckInModalOpen}
                onOk={handleCheckIn}
                onCancel={handleCancelCheckIn}
                loading={loadingCheckIn || loadingGetCheckIn || openModalLoading}
            >
                <Form
                    layout='vertical'
                    onFinish={handleSearch}
                    style={{
                        background: 'rgba(255,255,255,0.9)',
                        maxWidth: '50rem',
                        padding: '1rem',
                        minWidth: '60%',
                        borderRadius: borderRadiusLG
                    }}>
                    <Form.Item label='Firstname'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.firstname}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.firstname = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Lastname'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.lastname}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.lastname = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Group'>
                        <Select
                            value={checkInForm?.group}
                            size='large'
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.group = value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        >
                            <Select.Option value={1}>Group 1</Select.Option>
                            <Select.Option value={2}>Group 2</Select.Option>
                            <Select.Option value={3}>Group 3</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Hole'>
                        <Select
                            value={checkInForm?.hole}
                            size='large'
                            style={{ width: '100%' }}
                            onChange={(value) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.hole = value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        >
                            {new Array(18).fill(0).map((_, index) => (
                                <Select.Option key={index} value={index + 1}>Hole {index + 1}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Caddie No.'>
                        <Input
                            type='text'
                            size='large'
                            value={checkInForm?.caddieNo}
                            onChange={(e) => {
                                if (checkInForm) {
                                    const newCheckIn = new EventPlayer(checkInForm)
                                    newCheckIn.caddieNo = e.target.value
                                    setCheckInForm(newCheckIn)
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CheckInScreen