import { Button, Divider, Flex, Form, Input, List, Popconfirm, theme, Typography } from 'antd'
import { CheckCircleFilled, ClockCircleOutlined, FlagOutlined, SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSuscribeEvent } from '../hooks/eventCustomHook';
import LoadingScreen from './LoadingScreen';
import { EventPlayer } from '../domain/events/model';
import { useChecked } from '../hooks/checkInCustomHook';

const CheckedButton = (props: { checked: boolean, onClick: () => Promise<void> }) => {
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)
        props.onClick().finally(() => {
            setLoading(false)
        })
    }

    if (!props.checked) {
        return (
            <Popconfirm
                title="Let the caddie go?"
                onConfirm={handleClick}
                okText="Yes"
                cancelText="No"
            >
                <Button type='primary' loading={loading}>
                    Check
                </Button>
            </Popconfirm>
        )
    }

    return (
        <Button type='default' onClick={handleClick} loading={loading}>
            Remove
        </Button>
    )
}

function CaddieScreen() {
    const params = useParams()

    const navigate = useNavigate()

    if (!params?.id) {
        navigate(-1)
    }

    const { event, loading } = useSuscribeEvent(params?.id || '')
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [players, setPlayers] = useState(event?.players || [])
    const { Checked } = useChecked()

    const handleSearch = (input: string) => {
        const data = event?.players.filter((player) => player.isCheckIn() && player.caddieNo.toLocaleLowerCase().includes(input.toLocaleLowerCase()))

        setPlayers(data || [])
    }

    const handleCheck = async (id: string, checked: boolean) => {
        await Checked(event?.id || '', id, checked)
    }

    useEffect(() => {
        if (!loading) {
            const data = event?.players.filter((player) => player.isCheckIn())

            setPlayers(data || [])
        }
    }, [event, loading])

    if (loading) {
        return <LoadingScreen />
    }

    return (
        < >
            <Flex
                style={{
                    marginTop: '1.5rem',
                    background: colorBgContainer,
                    minHeight: '80vh',
                    padding: '2rem',
                    borderRadius: borderRadiusLG,
                }}
                vertical>
                <Form
                    layout='vertical'
                    onFinish={handleSearch}
                    style={{
                        background: 'rgba(255,255,255,0.9)',
                        padding: '1rem',
                        borderRadius: borderRadiusLG
                    }}>
                    <Typography.Title level={3} ><FlagOutlined /> Check Caddie</Typography.Title>
                    <Flex style={{ width: '100%', justifyContent: 'center' }}>
                        <img src={event?.getLogoUrl()} alt="avatar" style={{ maxWidth: '100%', minWidth: '3rem', minHeight: '1rem', maxHeight: '6rem', overflow: 'hidden' }} />
                    </Flex>
                    <Divider />
                    <Form.Item>
                        <Input
                            type='text'
                            placeholder='Search Caddie No'
                            prefix={<SearchOutlined />}
                            size='large'
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </Form.Item>
                    <Flex style={{ width: '100%' }}>
                        <List style={{ width: '100%' }}>
                            <List.Item>
                                <Typography.Text style={{ width: '5%' }} strong>Status</Typography.Text>
                                <Typography.Text style={{ width: '15%' }} strong>Caddie No.</Typography.Text>
                                <Typography.Text style={{ width: '20%' }} strong>Player Name</Typography.Text>
                                <Typography.Text style={{ width: '20%' }} strong>Hole</Typography.Text>
                                <Typography.Text style={{ width: '20%' }} strong>Group</Typography.Text>
                                <Typography.Text style={{ width: '20%' }} strong>Checked</Typography.Text>
                            </List.Item>
                            <Flex vertical style={{ width: '100%', height: '70vh', overflowY: 'auto', overflowX: 'hidden' }}>
                                {players.map((data: EventPlayer) => {
                                    return (
                                        <List.Item key={data.id}>
                                            <Typography.Text style={{ width: '5%' }}>
                                                {data.checked ? <CheckCircleFilled style={{ fontSize: '1.5rem', color: 'green' }} /> : <ClockCircleOutlined style={{ fontSize: '1.5rem', color: 'gray' }} />}
                                            </Typography.Text>
                                            <Typography.Text style={{ width: '15%' }}>{data.caddieNo}</Typography.Text>
                                            <Typography.Text style={{ width: '20%' }}>{data.firstname} {data.lastname}</Typography.Text>
                                            <Typography.Text style={{ width: '20%' }}>{data.hole}</Typography.Text>
                                            <Typography.Text style={{ width: '20%' }}>{data.group}</Typography.Text>
                                            <Flex style={{ width: '20%' }}>
                                                <CheckedButton checked={data.checked} onClick={() => handleCheck(data.id, !data.checked)} />
                                            </Flex>
                                        </List.Item>
                                    )
                                })}
                            </Flex>
                        </List>
                    </Flex>
                </Form>
            </Flex>
        </>
    )
}

export default CaddieScreen