import { Player } from "./model";
import PlayerRepository from "./repository";

export default class PlayerService {
    repo: PlayerRepository
    constructor(repo: PlayerRepository) {
        this.repo = repo
    }

    RequestNewPlayer(data: Player): Promise<Player> {
        return this.repo.RequestNewPlayer(data)
    }
}