import React from 'react';
import './App.css';
import LoadingScreen from './containers/LoadingScreen';
import { useAuth } from './hooks/userCustomHook';
import LoginScreen from './containers/LoginScreen';
import MainScreen from './containers/MainScreen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FormScreen from './containers/FormScreen';
import CaddieScreen from './containers/CaddieScreen';
import CheckInScreen from './containers/CheckInScreen';
import SystemLayout from './containers/SystemLayout';

function App() {
  const { isLogin, loading, } = useAuth()

  if (loading) {
    return (<LoadingScreen />)
  }

  if (!isLogin) {
    return (<LoginScreen />)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={(
          <SystemLayout>
            <MainScreen />
          </SystemLayout>
        )} />
        <Route path='/events/:id' element={(
          <SystemLayout>
            <MainScreen />
          </SystemLayout>
        )} />
        <Route path='/events/:id/checkins' element={(
          <SystemLayout>
            <CheckInScreen />
          </SystemLayout>
        )} />
        <Route path='/events/:id/form' element={(
          <SystemLayout>
            <FormScreen />
          </SystemLayout>
        )} />
        <Route path='/events/:id/caddie' element={(
          <SystemLayout>
            <CaddieScreen />
          </SystemLayout>
        )} />
        <Route path="*" element={(
          <SystemLayout>
            <MainScreen />
          </SystemLayout>
        )} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
