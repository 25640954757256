import { CheckInRepository } from "./repository";

export class CheckInService {
    repo: CheckInRepository;
    constructor(repo: CheckInRepository) {
        this.repo = repo
    }

    Checked(eventId: string, playerId: string, checked: boolean): Promise<void> {
        return this.repo.Checked(eventId, playerId, checked)
    }
}